<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="时间">
              <a-range-picker :defaultValue="[queryParam.start_date, queryParam.end_date]" @change="businessDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户">
              <a-select
                v-model="queryParam.customer_id"
                placeholder="请选择客户"
                style="width: 100%"
                @change="loadData"
                allowClear
              >
                <a-select-option v-for="op in customerData" :key="op.customer_id">
                  {{ op.nick_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="类别">
              <a-select
                v-model="queryParam.type"
                placeholder="请选择类别"
                style="width: 100%"
                @change="loadData"
              >
                <a-select-option v-for="op in typeData" :key="op.value">
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="loadData">查询</a-button>
      <a-button type="primary" icon="pie-chart" @click="queryStatisticsChart">统计图</a-button>
      <a-popover
        v-model="popVisible"
        title="选择年份"
        trigger="click"
      >
        <a slot="content">
          <p>
            <a-date-picker
              v-model="exportDate"
              mode="year"
              style="width: 200px"
              format="YYYY"
              placeholder="请选择年份"
              :open="yearOpen"
              @openChange="openChange"
              @panelChange="panelChange"
            ></a-date-picker>
          </p>
          <p style="text-align: right">
            <a-button type="primary" @click="exportYearBoxTotalDetailSheet">确定</a-button>
          </p>
        </a>
        <a-button type="primary" icon="download">导出年度箱量汇总表</a-button>
      </a-popover>
      <a-popover
        v-model="businessTypepopVisible"
        title="选择年份"
        trigger="click"
      >
        <a slot="content">
          <p>
            <a-range-picker @change="businessTypeDateChange" ></a-range-picker>
          </p>
          <p style="text-align: right">
            <a-button type="primary" @click="exportBusinessTypeTotalSheet">确定</a-button>
          </p>
        </a>
        <a-button type="primary" icon="download">导出业务类型箱量汇总表</a-button>
      </a-popover>
    </div>

    <a-table
      size="middle"
      :row-key="() => getRandomCode(8)"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </a-table>

    <a-modal
      title="统计图"
      :visible="visible"
      :width="1600"
      :footer="null"
      @cancel="visible = false"
    >
      <a-row :gutter="24">
        <a-col :span="24">
          <a-form layout="inline">
            <a-form-item label="客户">
              <a-select
                v-model="queryParam.customer_id"
                placeholder="请选择客户"
                style="width: 600px"
                @change="changeCunstomer"
                allowClear
              >
                <a-select-option v-for="op in customerData" :key="op.customer_id">
                  {{ op.nick_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-card title="订单统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[orderChartQueryParam.start_date, orderChartQueryParam.end_date]" @change="onDateChange"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="类别">
                  <a-select
                    v-model="orderChartQueryParam.type"
                    placeholder="请选择类别"
                    style="width: 100%"
                    @change="loadOrderChart"
                  >
                    <a-select-option v-for="op in typeData" :key="op.value">
                      {{ op.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="orderChart" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="委托车队箱TEU统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[supplierBoxTeuChartQueryParam.start_date, supplierBoxTeuChartQueryParam.end_date]" @change="supplierBoxTeuDateChange"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="类别">
                  <a-select
                    v-model="supplierBoxTeuChartQueryParam.type"
                    placeholder="请选择类别"
                    style="width: 100%"
                    @change="loadSupplierBoxTeuChart"
                  >
                    <a-select-option v-for="op in typeData" :key="op.value">
                      {{ op.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="supplierBoxTeuChart" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-card title="委托车队统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[supplierChartQueryParam.start_date, supplierChartQueryParam.end_date]" @change="onDateChange2"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="类别">
                  <a-select
                    v-model="supplierChartQueryParam.type"
                    placeholder="请选择类别"
                    style="width: 100%"
                    @change="loadSupplierChart"
                  >
                    <a-select-option v-for="op in typeData" :key="op.value">
                      {{ op.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="supplierChart" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="路径小箱数量统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[pieChartQueryParam1.start_date, pieChartQueryParam1.end_date]" @change="pieDateChange1"/>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="pieChart1" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-card title="路径大箱数量统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[pieChartQueryParam2.start_date, pieChartQueryParam2.end_date]" @change="pieDateChange2"/>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="pieChart2" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="箱型TEU统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[pieChartQueryParam4.start_date, pieChartQueryParam4.end_date]" @change="pieDateChange4"/>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="pieChart4" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="路径箱TEU统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[pieChartQueryParam3.start_date, pieChartQueryParam3.end_date]" @change="pieDateChange3"/>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="pieChart3" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="路径箱型TEU统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[pieChartQueryParam5.start_date, pieChartQueryParam5.end_date]" @change="pieDateChange5"/>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="存箱地">
                  <a-select
                    v-model="pieChartQueryParam5.start_point_id"
                    style="width: 100%"
                    @change="pieDateChange5"
                    showSearch
                    :filter-option="filterOption"
                    allowClear
                  >
                    <a-select-option v-for="op in pointData" :key="op.id">
                      {{ op.point_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="流向点">
                  <a-select
                    v-model="pieChartQueryParam5.end_point_id"
                    style="width: 100%"
                    @change="pieDateChange5"
                    showSearch
                    :filter-option="filterOption"
                    allowClear
                  >
                    <a-select-option v-for="op in pointData" :key="op.id">
                      {{ op.point_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="pieChart5" style="width: 716px; height: 500px;"></div>
          </a-card>
        </a-col>
      </a-row>
    </a-modal>
  </a-card>
</template>

<script>
import { getRandomCode, getFirstLetter } from '@/utils/util'
import moment from 'moment'
import {
  listAllStatisticsItmsOrderConShipment,
  listSupplierPayStatisticsItmsOrderConShipment,
  listSupplierBoxTeuStatisticsItmsOrderConShipment,
  exportYearBoxTotalDetailSheet,
  statisticsAreaLittleBoxNum,
  statisticsAreaLargeBoxNum,
  statisticsAreaTeuNum,
  statisticsContainerTeuNum,
  getPointInit,
  statisticsRouteContainerTeuNum,
  exportSupplierBusinessTypeTotalSheet,
  initItmsConCustomerInfo
} from '@/api/itms'
import { downloadExcel } from '@/api/common'

export default {
  name: 'ItmsConShipmentStatistics',
  data() {
    return {
      // 主页面查询条件
      queryParam: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        type: 1,
        customer_id: null
      },
      businessTypeParam: {
        start_date: null,
        end_date: null
      },
      customerData: [],
      supplierData: [],
      // 订单统计图查询条件
      orderChartQueryParam: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        type: 1,
        customer_id: null
      },
      // 委托车队应付金额统计图查询条件
      supplierChartQueryParam: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        type: 1,
        customer_id: null
      },
      // 委托车队箱量TEU统计图查询条件
      supplierBoxTeuChartQueryParam: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        type: 1,
        customer_id: null
      },
      pieChartQueryParam1: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        customer_id: null
      },
      pieChartQueryParam2: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      pieChartQueryParam3: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        customer_id: null
      },
      pieChartQueryParam4: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        customer_id: null
      },
      pieChartQueryParam5: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        start_point_id: null,
        end_point_id: null,
        customer_id: null
      },
      typeData: [
        { value: 1, name: '日' },
        { value: 2, name: '月' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '时间',
          dataIndex: 'date_str'
        },
        {
          title: '应收合计',
          dataIndex: 'charge_total'
        },
        {
          title: '应收净值合计',
          dataIndex: 'charge_net_total'
        },
        {
          title: '应付合计',
          dataIndex: 'pay_total'
        },
        {
          title: '利润合计',
          dataIndex: 'profit_total'
        },
        {
          title: '20尺箱量合计',
          dataIndex: 'twenty_count'
        },
        {
          title: '40尺箱量合计',
          dataIndex: 'forty_count'
        },
        {
          title: '全部箱量合计',
          dataIndex: 'count_total'
        }
      ],
      data: [],
      visible: false,
      // 订单统计图渲染配置
      orderChartOption: {},
      // 委托车队统计图应付金额渲染配置
      supplierChartOption: {},
      // 委托车队箱TEU统计图渲染配置
      supplierBoxTeuChartOption: {},
      pieChartOption1: {},
      pieChartOption2: {},
      pieChartOption3: {},
      pieChartOption4: {},
      popVisible: false,
      exportDate: null,
      yearOpen: false,
      pointData: [],
      businessTypepopVisible: false
    }
  },
  mounted() {
    this.loadData()
    initItmsConCustomerInfo().then(res => {
      this.customerData = res.CUSTOMER_LIST
      this.supplierData = res.SUPPLIER_LIST
    })
    getPointInit().then(res => {
      this.pointData = res
    })
  },
  methods: {
    getRandomCode,
    exportYearBoxTotalDetailSheet() {
      if (this.exportDate) {
        this.queryParam['year'] = this.exportDate.year()
        exportYearBoxTotalDetailSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false
        })
      } else {
        this.$message.warning('请选择导出年份')
      }
    },
    exportBusinessTypeTotalSheet() {
      if (this.businessTypeParam.start_date && this.businessTypeParam.end_date) {
        exportSupplierBusinessTypeTotalSheet(this.businessTypeParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.businessTypepopVisible = false
        })
      } else {
        this.$message.warning('请选择时间范围')
      }
    },
    openChange(status) {
      if (status) {
        this.yearOpen = true
      }
    },
    panelChange(value) {
      this.exportDate = value
      this.yearOpen = false
    },
    loadData() {
      listAllStatisticsItmsOrderConShipment(this.queryParam).then(res => {
        this.data = res
      })
    },
    businessDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    businessTypeDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.businessTypeParam['start_date'] = startDate
        this.businessTypeParam['end_date'] = endDate
      } else {
        this.businessTypeParam['start_date'] = null
        this.businessTypeParam['end_date'] = null
      }
    },
    onDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.orderChartQueryParam['start_date'] = startDate
        this.orderChartQueryParam['end_date'] = endDate
      } else {
        this.orderChartQueryParam['start_date'] = null
        this.orderChartQueryParam['end_date'] = null
      }
      this.loadOrderChart()
    },
    onDateChange2(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.supplierChartQueryParam['start_date'] = startDate
        this.supplierChartQueryParam['end_date'] = endDate
      } else {
        this.supplierChartQueryParam['start_date'] = null
        this.supplierChartQueryParam['end_date'] = null
      }
      this.loadSupplierChart()
    },
    supplierBoxTeuDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.supplierBoxTeuChartQueryParam['start_date'] = startDate
        this.supplierBoxTeuChartQueryParam['end_date'] = endDate
      } else {
        this.supplierBoxTeuChartQueryParam['start_date'] = null
        this.supplierBoxTeuChartQueryParam['end_date'] = null
      }
      this.loadSupplierBoxTeuChart()
    },
    pieDateChange1(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.pieChartQueryParam1['start_date'] = startDate
        this.pieChartQueryParam1['end_date'] = endDate
      } else {
        this.pieChartQueryParam1['start_date'] = null
        this.pieChartQueryParam1['end_date'] = null
      }
      this.loadPieChart1()
    },
    pieDateChange2(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.pieChartQueryParam2['start_date'] = startDate
        this.pieChartQueryParam2['end_date'] = endDate
      } else {
        this.pieChartQueryParam2['start_date'] = null
        this.pieChartQueryParam2['end_date'] = null
      }
      this.loadPieChart2()
    },
    pieDateChange3(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.pieChartQueryParam3['start_date'] = startDate
        this.pieChartQueryParam3['end_date'] = endDate
      } else {
        this.pieChartQueryParam3['start_date'] = null
        this.pieChartQueryParam3['end_date'] = null
      }
      this.loadPieChart3()
    },
    pieDateChange4(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.pieChartQueryParam4['start_date'] = startDate
        this.pieChartQueryParam4['end_date'] = endDate
      } else {
        this.pieChartQueryParam4['start_date'] = null
        this.pieChartQueryParam4['end_date'] = null
      }
      this.loadPieChart4()
    },
    pieDateChange5(date, dateStr) {
      if (dateStr && dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.pieChartQueryParam5['start_date'] = startDate
        this.pieChartQueryParam5['end_date'] = endDate
      } else {
        this.pieChartQueryParam5['start_date'] = moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
        this.pieChartQueryParam5['end_date'] = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.pieChartQueryParam5.start_point_id || this.pieChartQueryParam5.end_point_id) {
        this.loadPieChart5()
      }
    },
    // 查看统计图
    queryStatisticsChart() {
      this.visible = true
      // this.loadOrderChart()
      // this.loadSupplierChart()
      // this.loadSupplierBoxTeuChart()
      // this.loadPieChart1()
      // this.loadPieChart2()
      // this.loadPieChart3()
      // this.loadPieChart4()
      this.changeCunstomer()
    },
    changeCunstomer() {
      this.orderChartQueryParam.customer_id = this.queryParam.customer_id
      this.supplierChartQueryParam.customer_id = this.queryParam.customer_id
      this.supplierBoxTeuChartQueryParam.customer_id = this.queryParam.customer_id
      this.pieChartQueryParam1.customer_id = this.queryParam.customer_id
      this.pieChartQueryParam2.customer_id = this.queryParam.customer_id
      this.pieChartQueryParam3.customer_id = this.queryParam.customer_id
      this.pieChartQueryParam4.customer_id = this.queryParam.customer_id
      this.loadOrderChart()
      this.loadSupplierChart()
      this.loadSupplierBoxTeuChart()
      this.loadPieChart1()
      this.loadPieChart2()
      this.loadPieChart3()
      this.loadPieChart4()
      this.loadData()
    },
    // 加载订单统计图数据源
    loadOrderChart() {
      listAllStatisticsItmsOrderConShipment(this.orderChartQueryParam).then(res => {
        const xAxisData = []
        const chargeData = []
        const chargeNetData = []
        const payData = []
        const profitData = []
        const twentyData = []
        const fortyData = []
        const boxNumData = []
        for (let i = 0; i < res.length; i++) {
          const obj = res[i]
          xAxisData.push(obj.date_str)
          chargeData.push(obj.charge_total)
          chargeNetData.push(obj.charge_net_total)
          payData.push(obj.pay_total)
          profitData.push(obj.profit_total)
          twentyData.push(obj.twenty_count)
          fortyData.push(obj.forty_count)
          boxNumData.push(obj.count_total)
        }
        this.orderChartOption = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['应收合计', '应收净值合计', '应付合计', '利润合计', '20尺箱合计', '40尺箱合计', '全部箱合计']
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          },
          yAxis: {
            type: 'value'
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          series: [
            {
              name: '应收合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: chargeData
            },
            {
              name: '应收净值合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: chargeNetData
            },
            {
              name: '应付合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: payData
            },
            {
              name: '利润合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: profitData
            },
            {
              name: '20尺箱合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: twentyData
            },
            {
              name: '40尺箱合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: fortyData
            },
            {
              name: '全部箱合计',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              data: boxNumData
            }
          ]
        }
        this.drawOrderChart()
      })
    },
    // 绘画订单统计图
    drawOrderChart() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.orderChart)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.orderChart)
        }
        myChart.setOption(this.orderChartOption)
      }, 500)
    },
    // 加载委托车队统计图数据源
    loadSupplierChart() {
      listSupplierPayStatisticsItmsOrderConShipment(this.supplierChartQueryParam).then(res => {
        // 统计图数据源初始需要一些标识
        const xAxisData = ['supplierDate']
        const HFSupplierData = ['恒发']
        const PLSupplierData = ['鹏路']
        const SLSupplierData = ['三利']
        const ZJSupplierData = ['召集']
        for (let i = 0; i < res.length; i++) {
          const obj = res[i]
          // x坐标是否包含日期
          if (xAxisData.indexOf(obj.date_str) === -1) {
            // 不包含对应日期、判断对应车队、给对应其他车队赋值为0、表示当天此车队没有应付
            xAxisData.push(obj.date_str)
            if (obj.supplier_name === '恒发') {
              HFSupplierData.push(obj.pay_total)
              PLSupplierData.push(0)
              SLSupplierData.push(0)
              ZJSupplierData.push(0)
            }
            if (obj.supplier_name === '鹏路') {
              PLSupplierData.push(obj.pay_total)
              HFSupplierData.push(0)
              SLSupplierData.push(0)
              ZJSupplierData.push(0)
            }
            if (obj.supplier_name === '三利') {
              SLSupplierData.push(obj.pay_total)
              HFSupplierData.push(0)
              PLSupplierData.push(0)
              ZJSupplierData.push(0)
            }
            if (obj.supplier_name === '召集') {
              SLSupplierData.push(0)
              HFSupplierData.push(0)
              PLSupplierData.push(0)
              ZJSupplierData.push(obj.pay_total)
            }
          } else {
            // 包含此日期、表示当天两家车队都有应付、那就将之前数组末尾添加的 0 移除、添加新的应付金额
            if (obj.supplier_name === '恒发') {
              HFSupplierData.pop()
              HFSupplierData.push(obj.pay_total)
            }
            if (obj.supplier_name === '鹏路') {
              PLSupplierData.pop()
              PLSupplierData.push(obj.pay_total)
            }
            if (obj.supplier_name === '三利') {
              SLSupplierData.pop()
              SLSupplierData.push(obj.pay_total)
            }
            if (obj.supplier_name === '召集') {
              ZJSupplierData.pop()
              ZJSupplierData.push(obj.pay_total)
            }
          }
        }
        this.supplierChartOption = {
          tooltip: {
            trigger: 'axis'
          },
          dataset: {
            source: [
              xAxisData,
              HFSupplierData,
              PLSupplierData,
              SLSupplierData,
              ZJSupplierData
            ]
          },
          xAxis: {
            type: 'category',
            boundaryGap: false
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            top: '50%'
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          series: [
            {
              type: 'line',
              seriesLayoutBy: 'row',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              emphasis: { focus: 'series' }
            },
            {
              type: 'line',
              seriesLayoutBy: 'row',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              emphasis: { focus: 'series' }
            },
            {
              type: 'line',
              seriesLayoutBy: 'row',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              emphasis: { focus: 'series' }
            },
            {
              type: 'pie',
              id: 'pie',
              radius: '30%',
              center: ['50%', '25%'],
              emphasis: {
                focus: 'self'
              },
              label: {
                formatter: `{b}: {@${xAxisData[1]}} ({d}%)`
              },
              encode: {
                itemName: 'supplierDate',
                value: `${xAxisData[1]}`,
                tooltip: `${xAxisData[1]}`
              }
            }
          ]
        }
        this.drawSupplierChart()
      })
    },
    // 绘画委托车队统计图
    drawSupplierChart() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.supplierChart)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.supplierChart)
        }
        myChart.setOption(this.supplierChartOption)
        myChart.on('updateAxisPointer', (event) => {
          const xAxisInfo = event.axesInfo[0]
          if (xAxisInfo) {
            const dimension = xAxisInfo.value + 1
            myChart.setOption({
              series: {
                id: 'pie',
                label: {
                  formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                },
                encode: {
                  value: dimension,
                  tooltip: dimension
                }
              }
            })
          }
        })
      }, 500)
    },
    // 加载委托车队箱量TEU统计图数据源
    loadSupplierBoxTeuChart() {
      listSupplierBoxTeuStatisticsItmsOrderConShipment(this.supplierBoxTeuChartQueryParam).then(res => {
        const xAxisData = ['supplierDate']
        const HFSupplierData = ['恒发']
        const PLSupplierData = ['鹏路']
        const SLSupplierData = ['三利']
        const ZJSupplierData = ['召集']
        for (let i = 0; i < res.length; i++) {
          const item = res[i]
          if (xAxisData.indexOf(item.date_str) === -1) {
            xAxisData.push(item.date_str)
            if (item.supplier_name === '恒发') {
              HFSupplierData.push(item.box_teu_total)
              PLSupplierData.push(0)
              SLSupplierData.push(0)
              ZJSupplierData.push(0)
            }
            if (item.supplier_name === '鹏路') {
              PLSupplierData.push(item.box_teu_total)
              HFSupplierData.push(0)
              SLSupplierData.push(0)
              ZJSupplierData.push(0)
            }
            if (item.supplier_name === '三利') {
              SLSupplierData.push(item.box_teu_total)
              HFSupplierData.push(0)
              PLSupplierData.push(0)
              ZJSupplierData.push(0)
            }
            if (item.supplier_name === '召集') {
              SLSupplierData.push(0)
              HFSupplierData.push(0)
              PLSupplierData.push(0)
              ZJSupplierData.push(item.box_teu_total)
            }
          } else {
            if (item.supplier_name === '恒发') {
              HFSupplierData.pop()
              HFSupplierData.push(item.box_teu_total)
            }
            if (item.supplier_name === '鹏路') {
              PLSupplierData.pop()
              PLSupplierData.push(item.box_teu_total)
            }
            if (item.supplier_name === '三利') {
              SLSupplierData.pop()
              SLSupplierData.push(item.box_teu_total)
            }
            if (item.supplier_name === '召集') {
              ZJSupplierData.pop()
              ZJSupplierData.push(item.box_teu_total)
            }
          }
        }
        this.supplierBoxTeuChartOption = {
          tooltip: {
            trigger: 'axis'
          },
          dataset: {
            source: [
              xAxisData,
              HFSupplierData,
              PLSupplierData,
              SLSupplierData,
              ZJSupplierData
            ]
          },
          xAxis: {
            type: 'category',
            boundaryGap: false
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            top: '50%'
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          series: [
            {
              type: 'line',
              seriesLayoutBy: 'row',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              emphasis: { focus: 'series' }
            },
            {
              type: 'line',
              seriesLayoutBy: 'row',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              emphasis: { focus: 'series' }
            },
            {
              type: 'line',
              seriesLayoutBy: 'row',
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                }
              },
              emphasis: { focus: 'series' }
            },
            {
              type: 'pie',
              id: 'pie',
              radius: '30%',
              center: ['50%', '25%'],
              emphasis: {
                focus: 'self'
              },
              label: {
                formatter: `{b}: {@${xAxisData[1]}} ({d}%)`
              },
              encode: {
                itemName: 'supplierDate',
                value: `${xAxisData[1]}`,
                tooltip: `${xAxisData[1]}`
              }
            }
          ]
        }
        this.drawSupplierBoxTeuChart()
      })
    },
    // 绘画委托车队箱量TEU统计图
    drawSupplierBoxTeuChart() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.supplierBoxTeuChart)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.supplierBoxTeuChart)
        }
        myChart.setOption(this.supplierBoxTeuChartOption)
        myChart.on('updateAxisPointer', (event) => {
          const xAxisInfo = event.axesInfo[0]
          if (xAxisInfo) {
            const dimension = xAxisInfo.value + 1
            myChart.setOption({
              series: {
                id: 'pie',
                label: {
                  formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                },
                encode: {
                  value: dimension,
                  tooltip: dimension
                }
              }
            })
          }
        })
      }, 500)
    },
    // 加载路径小箱量统计饼图
    loadPieChart1() {
      statisticsAreaLittleBoxNum(this.pieChartQueryParam1).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.num, name: item.area_info })
        })
        this.pieChartOption1 = {
          tooltip: { trigger: 'item' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              label: { formatter: `{b}: ({d}%)` },
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawPieChart1()
      })
    },
    drawPieChart1() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.pieChart1)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.pieChart1)
        }
        myChart.setOption(this.pieChartOption1)
      }, 500)
    },
    // 加载路径大箱量统计饼图
    loadPieChart2() {
      statisticsAreaLargeBoxNum(this.pieChartQueryParam2).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.num, name: item.area_info })
        })
        this.pieChartOption2 = {
          tooltip: { trigger: 'item' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              label: { formatter: `{b}: {c} ({d}%)` },
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawPieChart2()
      })
    },
    drawPieChart2() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.pieChart2)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.pieChart2)
        }
        myChart.setOption(this.pieChartOption2)
      }, 500)
    },
    // 加载路径箱TEU统计饼图
    loadPieChart3() {
      statisticsAreaTeuNum(this.pieChartQueryParam3).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.teu, name: item.area_info })
        })
        this.pieChartOption3 = {
          // 设置标题
          title: {
              text: '', // 主标题 (主标题里面来动态显示当前数据总数)
              subtext: '总数', // 副标题
              x: 'center', // 标题x轴居中（也可以是: left || center || right || 百分比：xx% || xx(默认单位px)  ）
              y: 'center', // 标题y轴居中（也可以是: top || center || bottom || 百分比：xx% || xx(默认单位px)  ）
              itemGap: -5, // 主标题和副标题之间的距离(默认为 0)
              textStyle: { // 设置主标题样式
                  fontSize: 30,
                  color: '#666',
                  backroung: 'blue'
              },
              subtextStyle: { // 设置副标题样式
                  fontSize: 20,
                  color: '#666'
              }
          },
          tooltip: { trigger: 'item' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: ['30%', '60%'],
              label: { formatter: `{b}: {c}({d}%)` },
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        let totalNum = 0
        dataSource.forEach(v => {
            totalNum += v.value
        })
        this.pieChartOption3.title.text = totalNum
        this.drawPieChart3()
      })
    },
    // 加载路径箱TEU统计饼图
    loadPieChart4() {
      statisticsContainerTeuNum(this.pieChartQueryParam4).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.teu, name: item.container_size_type })
        })
        this.pieChartOption4 = {
          tooltip: { trigger: 'item' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              label: { formatter: `{b}: {c} ({d}%)` },
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawPieChart4()
      })
    },
    // 加载路径箱TEU统计饼图
    loadPieChart5() {
      statisticsRouteContainerTeuNum(this.pieChartQueryParam5).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.teu, name: item.container_size_type })
        })
        this.pieChartOption5 = {
          tooltip: { trigger: 'item' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              label: { formatter: `{b}: {c} ({d}%)` },
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawPieChart5()
      })
    },
    drawPieChart3() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.pieChart3)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.pieChart3)
        }
        myChart.setOption(this.pieChartOption3)
      }, 500)
    },
    drawPieChart4() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.pieChart4)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.pieChart4)
        }
        myChart.setOption(this.pieChartOption4)
      }, 500)
    },
    drawPieChart5() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.pieChart5)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.pieChart5)
        }
        myChart.setOption(this.pieChartOption5)
      }, 500)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style scoped>

</style>
